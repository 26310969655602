import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import Group from '../views/Group.vue'
import Entry from '../views/Entry.vue'
import Forbidden from '../views/Forbidden.vue'
import store from '../store/'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    meta: { isAuthenticated: true, roles: ['ais_operator_admin'] },
  },
  {
    path: '/group',
    name: 'Group',
    component: Group,
    meta: { isAuthenticated: true, roles: ['ais_operator_admin'] },
  },
  {
    path: '/entry',
    name: 'Entry',
    component: Entry,
    meta: { isAuthenticated: true, roles: ['ais_operator_admin'] },
  },
  {
    path: '/denied',
    name: 'Denied',
    component: Forbidden,
    meta: { isAuthenticated: false },
  },
  {
    path: "/*",
    meta: { isAuthenticated: false },
    component: () => import("../views/NotFound.vue")
  },
  {
    path: "/:catchAll(.*)",
    meta: { isAuthenticated: false },
    component: () => import("../views/NotFound.vue")
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.isAuthenticated) {
    if (!store.getters.isLoggedIn) {
        next({ name: 'Denied' })
    } else {
      if (to.meta.roles) {
        if (store.getters.getRoles.includes(to.meta.roles[0])) {
          next()
        }
        else {
          next({ name: 'Denied' })
        }
      }
      else {
        next()
      }
    }
  } else {
    next()
  }
})

export default router

<template>
  <main>
    <transition mode="out-in">
      <router-view />
    </transition>
  </main>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "App",
  components: {},
  created() {
    document.title = "Overview | AIS";
  },
  methods: {
    ...mapActions(["getData"]),
  },
  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Overview',
      // all titles will be injected into this template
      titleTemplate: '%s | AIS'
    },
};
</script>

<style>
@import '../public/color.css';
body {
  margin: 0;
}

#app {
  font-family: DM Sans, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>


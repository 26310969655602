<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="groups"
      sort-by="name"
      class="elevation-1"
      dense
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Gruppen</v-toolbar-title>
          <v-divider class="mx-4" inset vertical />
          <v-spacer />
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Hinzufügen
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <p v-html="formDescription" class="subtitle-2 text-left" />
                <v-divider class="mx-1" />
                <v-container>
                  <v-form ref="form_groupliste" v-model="valid">
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          :value="editedItem.name"
                          v-model="editedItem.name"
                          label="Name"
                          :rules="rules.name"
                        />
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          :value="editedItem.right"
                          v-model="editedItem.right"
                          label="Recht"
                          :rules="rules.right"
                          hint="Vorhandene Rechte: ais_user, ais_admin, ais_admin_operator"
                        />
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          :value="editedItem.rank"
                          v-model="editedItem.rank"
                          label="Rang"
                          :rules="rules.rank"
                          hint="Je höher die Zahl, desto weiter hinten wird die Gruppe dargestellt"
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" text @click="close">
                  Abbruch
                </v-btn>
                <v-btn color="primary" text @click="save">
                  Übernehmen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Möchten Sie die Gruppe entfernen?</v-card-title
              >
              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" text @click="closeDelete"
                  >Abbruch</v-btn
                >
                <v-btn color="primary" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)" color="error"> mdi-delete </v-icon>
      </template>
      <template v-slot:[`item.created`]="{ item }">
        <span>{{ formatDate(item.created) }}</span>
      </template>
      <template v-slot:[`item.updated`]="{ item }">
        <span>{{ formatDate(item.updated) }}</span>
      </template>
    </v-data-table>
    <v-container>
      <v-row>
        <v-spacer />
        <v-col cols="12" sm="6" md="6" class="text-right">
          <v-btn @click="initialize" outlined> Neu laden </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar" :timeout="snackbar_timeout" color="darklagoon">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="amarillo" text v-bind="attrs" @click="snackbar = false">
          Schliessen
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import GroupDataService from "../services/GroupDataService.js";
export default {
  data: () => ({
    valid: true,
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "ID", value: "id" },
      { text: "Name", value: "name" },
      { text: "Recht", value: "right" },
      { text: "Rang", value: "rank" },
      { text: "Erstellt", value: "created", dataType: "Date" },
      { text: "letztes Update", value: "updated", dataType: "Date" },
      { text: "Aktionen", value: "actions", sortable: false },
    ],
    rules: {
        name: [v => !!v || "Name erforderlich"],
        right: [v => !!v || "Recht erforderlich"],
        rank: [v => !!v || "Rang erforderlich", v => (!isNaN(parseFloat(v)) && v >= 0 && v <= 999) || "Zahl zwischen 0 und 999 erforderlich"]
      },
    group: [],
    groups: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      right: "",
      id: "",
      rank: "",
    },
    defaultItem: {
      name: "",
      right: "",
      id: "",
      rank: "",
    },
    isEditModus: false,
    snackbar: false,
    message: "",
    snackbar_timeout: 8000,
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Gruppe hinzufügen"
        : "Gruppe ändern";
    },
    formDescription() {
      return "Bitte hier die entsprechenden Daten zu einer Gruppe erfassen.</br>Berücksichtigen Sie dabei bitte das Recht für das für die Sichtbarkeit einer Gruppe benötigt wird.";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.loadGroups(true);
    },
    async loadGroups(ismessage) {
      let resp = await GroupDataService.getAll();
      if (resp.status == 200) {
        this.groups = resp.data;
        if (ismessage == true) {
          this.message = "Gruppen gelesen.";
          this.snackbar = true;
        }
      } else {
        this.message = resp.data;
        if (!this.message) {
          this.message = "Keine Daten vorhanden";
        }
        this.snackbar = true;
      }
    },
    editItem(item) {
      this.editedIndex = this.groups.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.isEditModus = true;
    },
    deleteItem(item) {
      this.editedIndex = this.groups.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.groups.splice(this.editedIndex, 1);
      this.closeDelete();
      this.deleteService();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.isEditModus = false;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.validate();
      if (this.valid) {
        if (this.editedIndex > -1) {
          this.updateService();
        } else {
          this.addService();
        }
        this.close();
      }
    },
    getGroup(value) {
      this.loadGroup(value);
    },
    async loadGroup(value) {
      let resp = await GroupDataService.getById(value);
      this.group = resp.data;
      if (resp.status == 200) {
        this.message = "Gruppe gelesen.";
        this.snackbar = true;
      } else {
        this.message = resp.data;
        this.snackbar = true;
      }
    },
    async addService() {
      try {
        let resp = await GroupDataService.create(this.editedItem);
        if (resp.status == 201) {
          this.message = "Änderungen wurden gespeichert.";
          this.loadGroups(false);
        } else {
          this.message = resp.errorMessage;
        }
      } catch (err) {
        this.message = err;
        this.initialize();
      }
      this.snackbar = true;
    },
    async updateService() {
      try {
        let resp = await GroupDataService.update(
          this.editedItem.id,
          this.editedItem
        );
        if (resp.status == 200) {
          this.message = "Änderungen wurden gespeichert.";
          this.loadGroups(false);
        } else {
          this.message = resp.errorMessage;
        }
      } catch (err) {
        this.message = err;
        this.initialize();
      }
      this.snackbar = true;
    },
    async deleteService() {
      try {
        let resp = await GroupDataService.delete(this.editedItem.id);
        if (resp.status == 204) {
          console.log("Erfolgreich gelöscht");
          this.message = "Änderungen wurden gespeichert.";
          this.loadGroups(false);
        } else {
          this.message = resp.errorMessage;
        }
      } catch (err) {
        this.message = err;
        this.initialize();
      }
      this.snackbar = true;
    },
    validate() {
      this.$refs.form_groupliste.validate();
    },
    formatDate(item) {
      if (item === null) {
        return "--- ";
      }
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZoneName: "short",
      };
      return new Date(item).toLocaleString("de-DE", options);
    },
  },
};
</script>

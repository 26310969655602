<template>
  <v-app-bar app dense clipped-left>
    <v-img
      max-height="100"
      max-width="100"
      src="./../assets/KommONE.svg"
    />
    
    <v-spacer />
    <div>
      <v-breadcrumbs :items="getCurrentNav">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </div>
    <v-spacer />
    <v-menu offset-y v-if="admin">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Admin
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in adminitems"
          :key="index" router :to="item.href"
        >
        <v-list-item-action>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-spacer />
    <div class="text-right">
      <v-btn class="mx-1" small fab dark color="primary" v-on:click="logout">
        <v-img max-height="25" max-width="25" src="./../assets/logout.png" />
      </v-btn>
    </div> </v-app-bar
></template>

<script>
export default {
  name: "TopBar",
  props: {
    navpos: {
      text: String,
      disabled: Boolean,
      href: String
    },
  },

  data() {
    return {
      admin: false,
      nav: this.navpos,
      adminitems: [
        {
          title: "Gruppen",
          href: "/group",
        },
        {
          title: "Einträge",
          href: "/entry",
        },
      ],
    };
  },
  computed: {
    getCurrentNav() {
      return this.nav;
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if(this.$store.getters.getRoles.includes("ais_operator_admin") || this.$store.getters.getRoles.includes("ais_admin") ){
        this.admin = true;
      }
    },
    logout() {
      try {
        this.$store.dispatch("logout");
        this.$keycloak.logout();
        if (this.$route.path != '/') {
          this.$router.push({ path: "/" });
        }
      } catch (error) {
        alert(error.message);
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.center {
  margin: auto;
  width: 70%;
  padding: 10px;
  text-align: center;
}
</style>
